// Bootstrap brand color customization


/*     brand Colors              */

$primary:              $purple-500 !default;
$info:                 $cyan-500 !default;
$success:              $green-500 !default;
$warning:              $orange-500 !default;
$danger:               $red-500 !default;
$rose:                 $pink-500 !default;
$inverse:              $black-color !default;
